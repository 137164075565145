import React, { useState } from 'react';
import Switch from "react-switch";
import { useAccountService } from '../services/AccountService';

function SwipeGeoSwitcher({ onSwipeLocalChange }) {
    const accountService = useAccountService();

    const [isSwipelocal, setIsSwipelocal] = useState(() => {
        const saved = accountService.requestService.authenticationService.storageService.loadLocalswipe();
        return saved === "true" || saved === true;
    });

    const getGeolocation = async () => {
        if ("geolocation" in navigator) {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const coords = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                        accountService.requestService.authenticationService.storageService.saveGeolocation(coords);
                        resolve(coords);
                    },
                    (error) => {
                        console.error("Error getting location:", error);
                        setIsSwipelocal(false);
                        reject(error);
                    }
                );
            });
        } else {
            console.error("Geolocation not supported");
            setIsSwipelocal(false);
            return null;
        }
    };

    const handleChange = async (checked) => {
        if (checked) {
            await getGeolocation();
        } else {
            accountService.requestService.authenticationService.storageService.removeGeolocation();
        }
        setIsSwipelocal(checked);
        accountService.requestService.authenticationService.storageService.saveLocalswipe(checked);
        onSwipeLocalChange(checked);
    };

    return (
        <div className='flex flex-row justify-center align-middle m-2'>
            <Switch id="switchswipegeo" onChange={handleChange} checked={isSwipelocal || false} />
            <span className='ml-2 text-sm align-middle'>Mijn omgeving - 5km Radius</span>
        </div>
    );
}

export default SwipeGeoSwitcher; 