import { useEffect, useState } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useAccountService } from '../services/AccountService';
import { useAuthenticationService } from '../services/AuthenticationService';

function SettingsPage() {
  const { trackPageView } = useMatomo()
  const { t } = useTranslation();

  const accountService = useAccountService();
  const authenticationService = useAuthenticationService();
  const navigate = useNavigate();
  const [panel, setPanel] = useState("");

  const [username, setUsername] = useState(() => {
    const saved = accountService.requestService.authenticationService.storageService.loadUsername();
    return saved || "";
  });

  useEffect(() => {
    trackPageView();
  });

  const signOut = () => {
    authenticationService.signOut();
    navigate(0);
  }

  const openNameChangePane = () => {
    setPanel("change-name");
  }

  const openSettingsStart = () => {
    setPanel();
  }

  const applyNameChange = () => {
    const newUsername = document.getElementById("username-input").value;
    try {
    authenticationService.requestService.post("account/name", {fullName: newUsername}, null, false);
    } catch (error) {
      console.error("Failed to change name:", error);
    }
    setUsername(newUsername); 
    localStorage.setItem("username", newUsername);
    openSettingsStart();
  }

  return (() => {
    switch(panel) {
      default:
        return <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start'
        }}>
          <button onClick={openNameChangePane} className='p-3 w-full border-b border-gray-300   content-center text-left select-none flex justify-between'>{t('settings.name')} <span className='select-text'>{username}<i className="arrow right ml-2"></i></span></button>
          <div className='p-3 w-full border-b border-gray-300 content-center text-left select-none flex justify-between'>{t('settings.email')} <span className='select-text'>{authenticationService.email}</span></div>
          <div className="p-3 w-full border-b border-gray-300 content-center text-left select-none flex justify-between">
          <span className="mb-2">
          {t('settings.language')}
          </span>
        <LanguageSwitcher />
        </div>

        <div className='p-3 w-full border-b border-gray-300 flex justify-end'>
        <button className='flex items-center gap-x-2' onClick={signOut}>{t('settings.logout')}

        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M10 3H6a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h4M16 17l5-5-5-5M19.8 12H9"/></svg>

        </button>
        </div>

        </div>
        case "change-name":
          return<form className='settings-pane p-2 w-full'>

          <div className='flex items-center justify-between'>
            <button onClick={openSettingsStart} 
            className='p-2 hover:bg-gray-100 rounded-full border border-gray-300 w-10 h-10'
            type='button'>
              
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="24" 
              height="24" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M19 12H5M12 19l-7-7 7-7"/>
            </svg>

            </button>
            <input id='username-input' type='text' defaultValue={username} className='border border-gray-300 rounded-md p-2'/>
            <button onClick={applyNameChange} type='button' className='shadow bg-sky-800 hover:bg-sky-700 focus:shadow-outline focus:outline-none text-white text-md m-3 p-2  rounded'>{t('settings.save')}</button>
            </div>
          </form>
    }
})();

}

export default SettingsPage;
