import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuthenticationService } from './services/AuthenticationService';

import './App.css';

import HomePage from './pages/HomePage';
import SwipePage from './pages/SwipePage';
import GalleryPage from './pages/GalleryPage';
import GallerymapPage from './pages/GallerymapPage';
import BuildingDetailPage from './pages/BuildingDetailPage';
import SettingsPage from './pages/SettingsPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import NavFooter from './components/NavFooter';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import OnlineIndicator from './components/OnlineIndicator';


// Component for public routes that should redirect to home if user is logged in
function PublicRoute({ children }) {
  const authenticationService = useAuthenticationService();
  const isAuthenticated = authenticationService.isTokenValid();
  const whitelist = ["/login", "/register", "/signup", "/signin", "/terms", "/privacy"];
  
  // Check if current path is in whitelist
  const currentPath = window.location.pathname;
  const isWhitelistedPath = whitelist.includes(currentPath);

  if (isAuthenticated && isWhitelistedPath) {
    return <Navigate to="/home" replace />;
  }

  return children;
}

function App() {
  return (
    <Router>
          <Routes>
            {/* Public routes that redirect to home if logged in */}
            <Route path="/login" element={
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            } />
            <Route path="/signup" element={
              <PublicRoute>
                <SignupPage />
              </PublicRoute>
            } />
            <Route path="/terms" element={
              <PublicRoute>
                <TermsPage />
              </PublicRoute>
            } />
            <Route path="/privacy" element={
              <PublicRoute>
                <PrivacyPage />
              </PublicRoute>
            } />         

            {/* Protected routes */}
            <Route path="/home" element={<HomePage />} />
            <Route path="/swipe" element={<SwipePage />} />
            <Route path="/gallery" element={<GalleryPage />} />
            <Route path="/gallerymap" element={<GallerymapPage Page />} />
            <Route path="/gallerydetail" element={<BuildingDetailPage />} />
            <Route path="/settings" element={<SettingsPage />} />

            {/* Redirect root to login */}
            <Route path="/" element={<Navigate to="/login" replace />} />
          </Routes>
        <NavFooter />

        <OnlineIndicator />
    </Router>
  );
}

export default App;
