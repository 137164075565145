import React from 'react';
import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

Chart.register(ArcElement, Tooltip, Legend, Title);

function CreateDoughnutData(props) {
  const { scoredBuildings, upvotedBuildings, downvotedBuildings } = props;

  const data = {
    labels: [`${downvotedBuildings} Downvoted`, `${upvotedBuildings} Upvoted`],
    datasets: [
      {
        data: [downvotedBuildings, upvotedBuildings],  // Hier passen we de dynamische data aan
        backgroundColor: [ 'rgb(220, 38, 38)', 'rgb(22, 163, 74)'],
        borderWidth: 2,
        radius: '80%',
        cutout: '70%',
      },
    ],
  };

  const options = {
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        position: 'bottom',
        title: {
          display: false,
          text: `${scoredBuildings}`,
          font: {
            family: 'Helvetica Neue',
          },
        },
      },
    },
  };

  return (
    <div>
      <Doughnut data={data} options={options} />
    </div>
  );
}

export default CreateDoughnutData;