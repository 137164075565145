import { useState } from 'react';
import RequestService from './RequestService';

export default class AccountService {
  baseAddress;
  requestService;

  constructor() {
    this.requestService = new RequestService();
  }

  async getStats() {
    return await this.requestService.get("account/stats");
  }
}

/**
 * 
 * @returns {AccountService}
 */
export function useAccountService() {
  const [accountService] = useState(new AccountService());
  return accountService;
}