import React, { Fragment, useState } from 'react';
import DorpleLogo from '../images/logo-large.svg';
import "@fontsource/raleway/700.css";
import { useTranslation } from 'react-i18next';


function HomePage() {
  const { t } = useTranslation();


  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <div className="w-52 max-w-52 pb-4">
        <img src={DorpleLogo} alt="Dorple Logo" />
      </div>
      <h1 className="text-6xl text-sky-800 apptitle font-bold">Dorple</h1>
      <p className="text-2xl text-sky-800 appsubtitle font-bold">{t('home.subtitle')}</p>
    </div>
  );
}

export default HomePage;
