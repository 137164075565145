import { useState } from 'react';
import AuthenticationService from './AuthenticationService';

export default class RequestService {
  baseAddress;
  authenticationService;

  constructor() {

    this.baseAddress = "https://back.dorple.me/api/";

    this.authenticationService = new AuthenticationService(this);
  }

  async request(endpoint, method, parameters = null, headers = {}, body = null, jsonResponse = true) {
    try {
      if (!headers) headers = {};
      headers = {
        ...headers,
        'Content-Type': 'application/json',
      };
  
      if (this.authenticationService.isTokenValid()) headers['Authorization'] = `Bearer ${this.authenticationService.token}`;
  
      let url = this.baseAddress + endpoint;
  
      if (parameters) url += "?" + new URLSearchParams(parameters).toString();
  
      if (body) try {
        body = JSON.stringify(body)
      } catch { }
  
      console.log("requesting", url, body);
      const response = await fetch(url, {
        method: method,
        headers: headers,
        body: body,
      });

      let parsedResponse = response;

      if (jsonResponse) try {
        parsedResponse = await response.json();
      } catch (error) {
        console.error("Failed to parse response:", error);
      }
  
      return parsedResponse;
    } catch (ex) {
      console.error(ex);
      throw ex;
    }
  }

  async post(endpoint, body, parameters = null, jsonResponse = true, headers = null) {
    return await this.request(endpoint, "POST", parameters, headers, body, jsonResponse);
  }
  async get(endpoint, parameters = null) {
    return await this.request(endpoint, "GET", parameters);
  }
}

export function useRequestService() {
  const [requestService] = useState(new RequestService());
  return requestService;
}