// custom-marker.tsx

import React from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import MakrerUrl from "../images/mapmarker.svg";
import { Marker, useMap } from "react-leaflet";

function CustomMarker({ position, children }) {
  const map = useMap();

  const customIcon = L.icon({
    iconUrl: MakrerUrl,
    iconSize: [30, 30],
    iconAnchor: [15, 10],
  });

  return (
    <Marker position={position} icon={customIcon}>
      {children}
    </Marker>
  );
}

export default CustomMarker;