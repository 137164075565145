import React from 'react';
import { useNavigate } from 'react-router-dom';
import DorpleLogo from '../images/logo-large.svg';
import { useTranslation } from 'react-i18next';

function TermsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToSignup = () => {
    navigate('/signup');
  };
  return (

    <div className="d-flex justify-content-center align-items-center  m-2">


<div className="max-h-12 h-12 w-full flex items-start justify-left p-2">
    <button
     onClick={goToSignup}
    className='p-2 hover:bg-gray-100 rounded-full border border-gray-300 w-10 h-10'
    type='button'>
                  <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="24" 
              height="24" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M19 12H5M12 19l-7-7 7-7"/>
            </svg>
    </button>
  </div>

  <div id="logo" className="flex flex-col justify-center items-center w-full pb-2 mb-3">
        <img className="bg-white bg-opacity-75 border-sky-800 w-14 h-14 max-w-14 border rounded-full m-2" src={DorpleLogo} alt="Dorple Logo" />
        <h1 className="text-6xl text-sky-800 apptitle font-bold">Dorple</h1>
        <p className="text-2xl text-sky-800 appsubtitle font-bold">{t('home.subtitle')}</p>
    </div>

      <div className="border rounded-lg shadow-md p-4">

    <div className="container align-items-left text-left mx-auto px-4 py-8 max-w-3xl">
      <h1 className="text-2xl font-bold text-sky-800 mb-6">
        {t('terms.title')}
      </h1>
      
      <div className=" text-leftprose prose-sky">
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-sky-700 mb-4">
            {t('terms.section1.title')}
          </h2>
          <p className="mb-4">{t('terms.section1.content')}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-sky-700 mb-4">
            {t('terms.section2.title')}
          </h2>
          <p className="mb-4">{t('terms.section2.content')}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-sky-700 mb-4">
            {t('terms.section3.title')}
          </h2>
          <p className="mb-4">{t('terms.section3.content')}</p>
        </section>
      </div>
    </div>
    </div>
    </div>
  );
}

export default TermsPage; 