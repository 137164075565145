import React, { useEffect, useState } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useNavigate, Link } from 'react-router-dom'; // Gebruik de juiste hook
import { useRequestService } from '../services/RequestService';
import DorpleLogo from '../images/logo-large.svg';
import { useTranslation, Trans } from 'react-i18next';
import LanguageSwitcher from '../components/LanguageSwitcher';

function SignupPage() {
  const { trackPageView, trackEvent } = useMatomo()
  const { t } = useTranslation();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);
  const navigate = useNavigate(); // Gebruik `navigate` in plaats van `history`
  const requestService = useRequestService();

  useEffect(() => {
    trackPageView();
  });

  const handleSignup = async () => {
    setError(''); // Reset error message

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validation for empty fields and email format
    if (!fullName || !email || !password || !confirmPassword) {
      setError(t('signup.allfields_error'));
      return;
    }

    if (!emailRegex.test(email)) {
      setError(t('signup.email_error'));
      return;
    }

    if (password !== confirmPassword) {
      setError(t('signup.confirm_password_error'));
      return;
    }

    if (!acceptedTerms) {
      setShowTermsError(true);
      return;
    }

    try {
      const response = await requestService.post('auth/signup', {
        fullName,
        email,
        password,
        role: 'CUSTOMER'
      });

      console.log('Signup successful:', response.data);
      navigate('/home');

    } catch (error) {
      setError(error.response?.data || t('signup.error'));
      console.error('Signup failed:', error);
    }

    trackEvent({ category: 'signup-page', action: 'signup' });
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 m-4">
    <div id="logo" className="flex flex-col justify-center items-center w-full pb-2 mb-3">
        <img className="bg-white bg-opacity-75 border-sky-800 w-14 h-14 max-w-14 border rounded-full m-2" src={DorpleLogo} alt="Dorple Logo" />
        <h1 className="text-6xl text-sky-800 apptitle font-bold">Dorple</h1>
        <p className="text-2xl text-sky-800 appsubtitle font-bold">{t('home.subtitle')}</p>
    </div>

      <div className="border rounded-lg shadow-md p-4">
        <form className="login-panel">
          {/* Render error message if exists */}
          {error && <p className="text-red-500 text-sm">{error}</p>}
          {showTermsError && (
              <p className="text-red-500 text-sm">
                {t('signup.terms.required')}
              </p>
            )}

          <input id='fullName' placeholder={t('signup.full_name')} value={fullName} type='text'
            className="border appearance-none  rounded m-3 p-2 text-gray-700 leading-tight focus:outline-none bg-white border-gray-300  focus:bg-sky-50 focus:border-sky-700"
            onChange={(e) => setFullName(e.target.value)} />
          <input placeholder={t('signup.email')} id='email' value={email} type='email'
            className="border appearance-none  rounded m-3 p-2 text-gray-700 leading-tight focus:outline-none bg-white border-gray-300  focus:bg-sky-50 focus:border-sky-700"
            onChange={(e) => setEmail(e.target.value)} />
          <input placeholder={t('signup.password')} id='password' type='password' value={password}
            className="border appearance-none  rounded m-3 p-2 text-gray-700 leading-tight focus:outline-none bg-white border-gray-300  focus:bg-sky-50 focus:border-sky-700"
            onChange={(e) => setPassword(e.target.value)} />
          <input placeholder={t('signup.confirm_password')} id='confirmPassword' type='password'
            className="border appearance-none  rounded m-3 p-2 text-gray-700 leading-tight focus:outline-none bg-white border-gray-300  focus:bg-sky-50 focus:border-sky-700"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)} />

          <div className="my-1 mx-3">
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="terms"
                  type="checkbox"
                  checked={acceptedTerms}
                  onChange={(e) => {
                    setAcceptedTerms(e.target.checked);
                    if (e.target.checked) setShowTermsError(false);
                  }}
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-sky-300"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="terms" className="font-light text-gray-600">
                  <Trans
                    i18nKey="signup.terms.checkbox"
                    components={{
                      terms: <Link to="/terms" className="font-medium text-sky-600 hover:underline" />,
                      privacy: <Link to="/privacy" className="font-medium text-sky-600 hover:underline" />
                    }}
                  />
                </label>
              </div>
            </div>

          </div>

          <button className="shadow bg-sky-800 hover:bg-sky-700 focus:shadow-outline focus:outline-none text-white text-md m-3 p-2 rounded"
            type="button"
            onClick={handleSignup}> {t('signup.signup')}
          </button>

          <div className="text-center text-sm">

          <Trans
                    i18nKey="signup.already_registered.reference"
                    components={{
                      login: <Link to="/login" className="font-medium text-sky-600 hover:underline" />,
                    }}
                  />
          </div>
        </form>
      </div>

      <div className="flex grid-cols-1 place-content-center content-center justify-center items-center w-full pt-2 mt-3">
    <LanguageSwitcher />
    </div>

    </div>
  );
}

export default SignupPage;
