import { useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import RequestService from './RequestService';
import StorageService from './StorageService';

/**
 * 
 */
export default class AuthenticationService {
  _token;
  _requestService;
  storageService;

  constructor(requestService) {
    this._requestService = requestService;
    this.storageService = new StorageService();
  }

  /**
   * @type { RequestService }
   */
  get requestService() {
    if (!this._requestService) this._requestService = new RequestService();
    return this._requestService;
  }
  
  get token() {
    if (this._token && this.isTokenValid(this._token)) return this._token;
    return this._token = this.storageService.loadToken();
  }

  /**
   * Checks wether a JWT is still valid.
   * @param {string} token The JWT to validate.
   * @returns `true` if the token is valid.
   */
  isTokenValid(token = null) {
    if (!token) token = this.token;
    if (!token) return false;

    const decoded = this.decodeToken(token);
    if (!decoded || !decoded.exp) return false;

    const userRoles = decoded.roles; // Hier haal je de rollen uit de payload
    // console.log("User Roles:", userRoles);

    // Huidige tijd in seconden
    const currentTime = Date.now() / 1000;

    // Controleer of het token verlopen is
    return decoded.exp > currentTime;
  };

  decodeToken(token = null) {
    if (token) try {
      return jwtDecode(token);
    } catch (error) {
      console.error("Error decoding token:", error, token);
    }
    return null;
  }

  get decodedToken() {
    return this.decodeToken(this.token);
  }

  get email() {
    return this.decodedToken ? this.decodedToken.email : null;
  }

  get roles() {
    return this.decodedToken ? this.decodedToken.roles : null;
  }

  async signIn(email, password) {
    const result = await this.requestService.post('auth/signin', { email, password });

    if (result) {
      // console.log("Successfully logged in.", result);
      this.storageService.saveToken(result.jwt);
      this.storageService.saveUserId(result.userid);
      this.storageService.saveUsername(result.username);
    } else {
      console.error("Failed to log in.");
      // setError(data.message || 'Login failed');
    }
    return result;
  }

  async signOut() {
    this.storageService.removeToken();
  }

}

export function useAuthenticationService() {
  const [authenticationService] = useState(new AuthenticationService());
  return authenticationService;
}