import React, { useState, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useRequestService } from '../services/RequestService';
import DorpleLogo from '../images/logo-large.svg';


function LoginPage() {

  const { trackPageView } = useMatomo()


  useEffect(() => {
    trackPageView()
  }, []);


  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const requestService = useRequestService();
  
  const handleLogin = async () => {
    try {
      if (!email || !password) {
        setError(t('login.error'));
        return;
      }

      const result = await requestService.authenticationService.signIn(email, password);

      if (result) {
        console.log("Successfully logged in.", result);
        setEmail('');
        setPassword('');
        navigate('/home');
      } else {
        console.error("Failed to log in.", result);
        setError(result.message || 'Login failed');
        // setError(data.message || 'Login failed');
      }
    } catch (error) {
      console.error('Login failed:', error.message);
      setError('Invalid email or password.');
    }
  };

  return (


<div>

    <div className="d-flex justify-content-center align-items-center vh-100 m-4 vw-100" >

    <div id="logo" className="flex flex-col justify-center items-center w-full pb-2 mb-3">
        <img className="bg-white bg-opacity-75 border-sky-800 w-14 h-14 max-w-14 border rounded-full m-2" src={DorpleLogo} alt="Dorple Logo" />
        <h1 className="text-6xl text-sky-800 apptitle font-bold">Dorple</h1>
        <p className="text-2xl text-sky-800 appsubtitle font-bold">{t('home.subtitle')}</p>
    </div>

    <div className="border rounded-lg shadow-md p-4">
        <form className="login-panel" onSubmit={handleLogin}>
          {error && <p className="text-red-600 text-sm">{error}</p>}
          <input
            className="border appearance-none  rounded m-3 p-2 text-gray-700 leading-tight focus:outline-none bg-white border-gray-300  focus:bg-sky-50 focus:border-sky-700"
            placeholder={t('login.email')}
            id='email'
            value={email}
            type='email'
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            className="border appearance-none  rounded m-3 p-2 text-gray-700 leading-tight focus:outline-none bg-white border-gray-300  focus:bg-sky-50 focus:border-sky-700"
            placeholder={t('login.password')}
            id='password'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          
          <button
            type="button"
            className="shadow bg-sky-800 hover:bg-sky-700 focus:shadow-outline focus:outline-none text-white text-md m-3 p-2  rounded"
            onClick={handleLogin}
          >
            {t('login.button')}
          </button>
          <div className="text-center text-sm">

            <Trans
                    i18nKey="login.not_registered.reference"
                    components={{
                      signup: <Link to="/signup" className="font-medium text-sky-600 hover:underline" />,
                    }}
                  />
          </div>
        </form>
    </div>

    <div className="flex grid-cols-1 place-content-center content-center justify-center items-center w-full pt-2 mt-3">
    <LanguageSwitcher />
    </div>




    </div>








    </div>
  );
}

export default LoginPage;