import React, { useState, useEffect } from 'react';
import { useIsOnline } from 'react-use-is-online';

import { useTranslation } from 'react-i18next';

function OnlineIndicator() {
  const { t } = useTranslation();
  const { isOffline } = useIsOnline();

  return (
    isOffline ? (
      <div id="info-popup" 
           className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full  ${!isOffline ? 'invisible' : ''}`}>
        <div className="relative p-4 w-full max-w-lg h-full md:h-auto ">
          <div className="relative p-4 bg-sky-50 rounded-lg border border-grey-300 shadow dark:bg-gray-800 md:p-8">
            <div className="mb-4 text-sm font-light text-gray-500 dark:text-gray-400 flex flex-col items-center">
              <svg 
              className="size-8 text-gray-500 dark:text-gray-400 group-hover:text-sky-600 dark:group-hover:text-sky-500"
                fill="#000000" 
                viewBox="0 0 36 36"
              >
                <path className="clr-i-outline clr-i-outline-path-1" d="M18,24.42a4,4,0,1,0,4,4A4,4,0,0,0,18,24.42Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,18,30.42Z"/>
                <path className="clr-i-outline clr-i-outline-path-2" d="M26.21,21.85a1,1,0,0,0-.23-1.4,13.56,13.56,0,0,0-5-2.23l3.87,3.87A1,1,0,0,0,26.21,21.85Z"/>
                <path className="clr-i-outline clr-i-outline-path-3" d="M18.05,10.72a20.88,20.88,0,0,0-4.16.43l1.74,1.74a19,19,0,0,1,2.42-.17A18.76,18.76,0,0,1,28.64,16a1,1,0,0,0,1.12-1.65A20.75,20.75,0,0,0,18.05,10.72Z"/>
                <path className="clr-i-outline clr-i-outline-path-4" d="M33.55,8.2A28.11,28.11,0,0,0,8.11,5.36L9.69,6.93A26,26,0,0,1,32.45,9.87a1,1,0,0,0,1.1-1.67Z"/>
                <path className="clr-i-outline clr-i-outline-path-5" d="M1.84,4.75,4.27,7.18c-.62.34-1.23.7-1.83,1.1A1,1,0,1,0,3.56,9.94C4.26,9.47,5,9,5.74,8.65l3.87,3.87A20.59,20.59,0,0,0,6.23,14.4,1,1,0,0,0,7.36,16a18.82,18.82,0,0,1,3.77-2l4.16,4.16A13.51,13.51,0,0,0,10,20.55a1,1,0,0,0,1.18,1.61A11.52,11.52,0,0,1,17,20l10.8,10.8,1.41-1.41-26-26Z"/>
                <rect x="0" y="0" width="36" height="36" fillOpacity="0"/>
              </svg>

              <h3 className="mb-3 text-xl font-bold text-gray-900 dark:text-white">{t('offline.title')}</h3>

              <p>
              {t('offline.subtitle')}
              </p>
            </div>
          </div>
        </div>
      </div>
    ) : null
  );
}

export default OnlineIndicator;
